<template>
  <div>
    <eden-page-header title="Issues Tracker" subtitle="Manage Issue Categories">
      <template slot="actions">
        <div class="page--head-actions">
          <el-button type="primary" @click="command({ action: 'add' })">
            Add issue category
          </el-button>
        </div>
      </template>
    </eden-page-header>
    <eden-table-actions :title="title" @search="search">
      <template slot="actions">
        <el-row type="flex" justify="end" align="middle" :gutter="10">
          <el-col :span="10">
            <el-select
              v-model="departmentFilter"
              value-key="id"
              @change="filterDepartments"
            >
              <el-option
                v-for="(type, idx) in departments"
                :key="idx"
                :label="type.label"
                :value="type.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="10">
            <el-select
              v-model="servicesFilter"
              value-key="id"
              placeholder="Select Service"
              @change="filterServices"
            >
              <el-option
                v-for="(service, idx) in services"
                :key="idx"
                :label="service.label"
                :value="service.value"
              >
              </el-option>
            </el-select>
          </el-col>

          <el-col :span="5" class="d-none">
            <div class="is-flex is-justify-end">
              <issues-filter
                :disabled="loading"
                :clear="filterParams.clear"
                @filter="filter"
              />
            </div>
          </el-col>
        </el-row>
      </template>
    </eden-table-actions>

    <template>
      <eden-loader v-if="loading" />
      <template v-else>
        <eden-filter-items
          v-if="filterParams.status"
          :params="filterParams.paramsLabel"
          @clear-filter="clearFilter"
          @clear-filters="clearFilters"
        />
        <template v-if="issues.length">
          <el-table :data="issues">
            <el-table-column min-width="250">
              <template #header>
                <eden-table-column-header
                  :label="'Category Name'"
                  :property="'name'"
                  :sort-property="sort.property"
                  @sort="sortPageData($event, 'name')"
                />
              </template>
              <template v-slot="scope">
                <span class="font-sm"> {{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column min-width="250">
              <template #header>
                <eden-table-column-header
                  :label="'Related Department'"
                  :property="'department'"
                  :sort-property="sort.property"
                  @sort="sortPageData($event, 'department')"
                />
              </template>
              <template v-slot="scope">
                <span class="font-sm"> {{ scope.row.department }}</span>
              </template>
            </el-table-column>

            <el-table-column min-width="250">
              <template #header>
                <eden-table-column-header
                  :label="'Related Service'"
                  :property="'service'"
                  :sort-property="sort.property"
                  @sort="sortPageData($event, 'service')"
                />
              </template>
              <template v-slot="scope">
                <eden-services-list
                  v-if="scope.row.service"
                  :type="'single'"
                  :service="scope.row.service"
                />
                <span v-else>-</span>
              </template>
            </el-table-column>

            <el-table-column min-width="200">
              <template #header>
                <eden-table-column-header
                  :label="'Resolution Time'"
                  :property="'resolution_time'"
                  :sort-property="sort.property"
                  @sort="sortPageData($event, 'resolution_time')"
                />
              </template>
              <template v-slot="scope">
                <span class="font-sm">
                  {{ scope.row.resolution_time }}
                  {{ formatText(scope.row.resolution_unit) }}
                </span>
              </template>
            </el-table-column>

            <el-table-column width="60">
              <template v-slot="scope">
                <el-dropdown @command="command" class="more">
                  <span class="el-dropdown-link">
                    <i class="eden-icon-more"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      icon="eden-icon-edit-write"
                      :command="{
                        action: 'edit',
                        id: scope.row.id,
                        index: scope.$index,
                      }"
                      >Edit Issue Category</el-dropdown-item
                    >
                    <el-dropdown-item
                      icon="eden-icon-delete"
                      :command="{
                        action: 'delete',
                        id: scope.row.id,
                        index: scope.$index,
                      }"
                      >Delete Issue Category</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <eden-content-empty v-else :text="'No issue categories'" />
      </template>
    </template>

   
    <issue-categories-form
      :show.sync="issue.visibility"
      @success="getCategories"
      :action="issue.action"
      :issue="issue.data"
    />
    <issue-categories-delete
      :show.sync="showIssueCategoryDelete"
      :issue="issue.data"
      @success="getCategories"
    />
  </div>
</template>
  
  <script>
import categories from "@/requests/feedback/categories";
import IssueCategoriesForm from "@/components/Feedback/Issues/Categories/IssueCategoriesForm";
import IssueCategoriesDelete from "@/components/Feedback/Issues/Categories/IssueCategoriesDelete";
import IssuesFilter from "@/components/Feedback/Issues/IssuesFilter";

export default {
  name: "IssuesCategories",
  components: { IssueCategoriesForm, IssueCategoriesDelete , IssuesFilter },
  data() {
    return {
      loading: false,
      issues: [],
      showIssueCategoryDelete: false,
      issue: {
        action: "add",
        visibility: false,
        data: {},
      },
      stringedParams: "",
      sort: {
        property: "name",
        direction: "asc",
      },
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      servicesFilter: "All Services",
      departmentFilter: "All Departments",
      services: [
        { label: "Food Serivice", value: "meal" },
        { label: "Laundry Service", value: "laundry" },
        { label: "Cleaning Serivice", value: "cleaning" },
        { label: "Beauty Service", value: "beauty" },
        { label: "All Services", value: "" },
      ],
      departments: [
        { label: "All Departments", value: "" },
        { label: "Food Production Team", value: "food production team" },
        { label: "Supply", value: "supply" },
        { label: "Product", value: "product" },
        { label: "Engineering", value: "engineering" },
        { label: "Finance", value: "finance" },
      ],
    };
  },
  computed: {
    title() {
      return `${this.issues.length} Issues Categories`;
    },
  },
  created() {
    this.getCategories();
  },
  methods: {
    add() {
      this.showIssueCategoriesForm = true;
    },
    search(query) {
      this.loading = true;
      let searchQuery = encodeURI("?name=" + query);
      categories
        .category(searchQuery)
        .then((response) => {
          this.loading = false;
          const { status, data } = response.data;
          if (status) {
            this.departmentFilter = null;
            this.serviceFilter = null;
            this.filterParams = {
              clear: false,
              status: false,
              params: {},
              paramsLabel: {},
            };

            this.issues = data;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.loading = false;
        });
    },
    sortPageData(direction, property) {
      this.sort.property = property;
      this.sort.direction = direction;
      this.issues = this.sortList(this.issues, property, direction);
    },
    filter({ params, paramsLabel }) {
      const stringedParams = "?" + this.sortObjectAsParams(params);
      this.filterParams = {
        status: true,
        params: { ...this.filterParams.params, ...params },
        paramsLabel: { ...this.filterParams.paramsLabel, ...paramsLabel },
      };
      this.getCategories(stringedParams);
    },
    filterDepartments(filter) {
      if (filter) {
        this.filter({
          params: { ...this.filterParams.params, department: filter },
          paramsLabel: {
            ...this.filterParams.paramsLabel,
            department: {
              key: "department",
              label: "Departments",
              type: "list",
              options: this.departments,
              value: [filter],
            },
          },
        });
      } else {
        this.clearFilter("department");
      }
    },
    filterServices(filter) {
      if (filter) {
        this.filter({
          params: { ...this.filterParams.params, service: filter },
          paramsLabel: {
            ...this.filterParams.paramsLabel,
            service: {
              key: "service",
              label: "Service",
              type: "list",
              options: this.services,
              value: [filter],
            },
          },
        });
      } else {
        this.clearFilter("service");
      }
    },
    clearFilter(key) {
      delete this.filterParams.params[key];
      delete this.filterParams.paramsLabel[key];

      const keys = this.filterParams.params;
      if (Object.keys(keys).length > 1) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        this.clearFilters();
      }
    },
    clearFilters() {
      this.departmentFilter = null;
      this.serviceFilter = null;

      this.filterParams = {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      };
      this.getCategories();
    },
    getCategories() {
      this.loading = true;
      const stringedParams = this.sortObjectAsParams(this.filterParams.params);
      this.stringedParams = stringedParams
      const paramsPage = `?${this.stringedParams}`
      categories
        .category(paramsPage)
        .then((response) => {
          this.loading = false;
          const { status, data } = response.data;
          if (status) {
            this.issues = data;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.loading = false;
        });
    },
    command(command) {
      switch (command.action) {
        case "add":
          this.issue.data = {};
          this.issue.action = "add";
          this.issue.visibility = true;
          break;
        case "edit":
          this.issue.data = this.issues[command.index];
          this.issue.action = "edit";
          this.issue.visibility = true;
          break;
        case "delete":
          this.issue.data = this.issues[command.index];
          this.showIssueCategoryDelete = true;
          break;
        default:
          break;
      }
    },
  },
};
</script>
  