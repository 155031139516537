<template>
    <eden-confirm-dialog
      :title="'Delete this category?'"
      :button-type="'danger'"
      :button-text="'Yes, delete'"
      :button-status="deleting"
      :show.sync="setShow"
      @confirm="deleteIssueCategory"
    >
      <p>
        Are you sure you want to delete this issue category? If there are any issues attached to the category, they won’t be deleted but they won’t have a category attached to them.
      </p>
    </eden-confirm-dialog>
  </template>
  
  <script>
import categories from "@/requests/feedback/categories";
  
  export default {
    name: "IssueCategoriesDelete",
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      issue: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        deleting: false,
      };
    },
    computed: {
      setShow: {
        get() {
          return this.show;
        },
        set(value) {
          this.$emit("update:show", value);
        },
      },
    },
    methods: {
      deleteIssueCategory() {
        this.deleting = true;
        const payload = {
          category_id: this.issue.id,
        };
        categories
          .delete(payload)
          .then((response) => {
            if (response.data.status) {
              this.deleting = false;
              this.$message.success(response.data.message);
              this.$emit("success");
              this.setShow = false;
            }
          })
          .catch((error) => {
            this.$message.error(error.response.data.message);
            this.deleting = false;
            this.setShow = false;
          });
      },
    },
  };
  </script>
  
  <style scoped lang="scss"></style>
  