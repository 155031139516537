<template>
  <eden-filter :width="270" :properties.sync="properties" :disabled="disabled" :loading="loading" @open="getData"
    @reset="reset" @filter="filter" />
</template>

<script>
import { setDepartments } from "../feedback.config";
import customers from "@/requests/customers/individual";
import categories from "@/requests/feedback/categories";
import * as actions from "@/store/action-types";

export default {
  name: "IssuesFilter",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      properties: {
        service: {
          key: "issue_service",
          label: "Services",
          type: "list",
          options: [],
          value: [],
        },
        status: {
          key: "status",
          label: "Status",
          type: "list",
          options: [
            {
              label: "Not started",
              value: "not-started",
            },
            {
              label: "Ongoing",
              value: "ongoing",
            },
            {
              label: "Blocked",
              value: "blocked",
            },
            {
              label: "Resolved",
              value: "resolved",
            },
          ],
          value: [],
        },
        category: {
          key: "issue_category",
          label: "Issue Category",
          type: "list",
          options: [],
          value: [],
          searchable: true,
        },
        department: {
          key: "department",
          label: "Department",
          type: "list",
          options: [],
          value: [],
          searchable: true,
        },
        customer: {
          key: "affected_user_id",
          label: "Customer",
          type: "list",
          options: [],
          value: [],
          searchable: true,
        },
        gardener: {
          key: "gardener_id",
          label: "Gardener",
          type: "list",
          options: [],
          value: [],
          searchable: true,
        },
        date: {
          key: ["start_date", "end_date"],
          label: "Date Created",
          type: "daterange",
          value: [],
        },
        priority: {
          key: "priority",
          label: "Priority",
          type: "list",
          options: [
            {
              label: "Normal",
              value: "low",
            },
            {
              label: "High",
              value: "high",
            },
          ],
          value: [],
        },
      },
    };
  },
  computed: {
    gardeners() {
      return this.$store.getters.gardeners_list;
    },
    services() {
      return this.$store.getters.services;
    },
  },
  watch: {
    clear() {
      if (this.clear) {
        this.reset();
      }
    },
  },
  methods: {
    getData() {
      this.getGardeners();
      this.getCustomers();
      this.setDepartmentOptions();
      this.setServices();
      this.getCategories()
    },
    setServices() {
      this.properties.service.options = this.services.map((service) => {
        return {
          label: this.formatText(service.service_name),
          value: service.service_name.toLowerCase(),
        };
      });
    },
    getCategories() {
      if (this.properties.category.options.length) {
        return;
      }
      categories
        .index()
        .then((response) => {
          this.loading = false;
          const { status, data } = response.data;
          if (status) {
            this.categories = data;
            this.properties.category.options = this.categories.map(
              (category) => {
                return {
                  label: this.formatText(category.name),
                  value: category.id,
                };
              },
            );
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.loading = false;
        });
    },
    getCustomers() {
      if (this.properties.customer.options.length) {
        return;
      }
      customers
        .strippedList()
        .then((response) => {
          if (response.data.status) {
            this.customers = response.data.data.users;
            this.properties.customer.options = this.customers.map(
              (customer) => {
                return {
                  label: this.formatName(customer.name),
                  value: customer.id,
                };
              },
            );
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch();
    },
    getGardeners() {
      if (this.properties.gardener.options.length) {
        this.loading = false;
        return;
      }

      this.gardeners.length
        ? this.setGardenerOptions()
        : this.$store.dispatch(actions.GET_GARDENERS_LIST).then(() => {
          this.setGardenerOptions();
        });
    },
    setGardenerOptions() {
      this.properties.gardener.options = this.gardeners.map((gardener) => {
        return {
          label: this.formatName(gardener.name),
          value: gardener.id,
        };
      });
      this.loading = false;
    },
    setDepartmentOptions() {
      this.properties.department.options = setDepartments().map(
        (department) => {
          return {
            label: department.name,
            value: department.name,
          };
        },
      );
      this.loading = false;
    },
    reset() {
      Object.keys(this.properties).forEach((key) => {
        this.properties[key].value = [];
      });
    },
    filter() {
      let params = {
        params: {},
        paramsLabel: {},
      };

      Object.keys(this.properties).forEach((property) => {
        const { key, type, value } = this.properties[property];
        if (value && value.length) {
          switch (type) {
            case "list":
              params.params[key] = value[0];
              params.paramsLabel[key] = this.properties[property];
              break;
            case "list-multiple":
              params.params[key] = value;
              params.paramsLabel[key] = this.properties[property];
              break;
            case "daterange":
              params.params[key[0]] = this.properties[property].value[0];
              params.params[key[1]] = this.properties[property].value[1];
              params.paramsLabel[key] = this.properties[property];
              break;
            default:
              break;
          }
        }
      });
      if (Object.keys(params.params).length) {
        this.$emit("filter", {
          params: params.params,
          paramsLabel: params.paramsLabel,
        });
      } else {
        this.$message.info("Filtering requires at least one property");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
