<template>
  <el-drawer
    :title="`${formatText(action)} Issue Category`"
    :visible.sync="setShow"
    direction="rtl"
    append-to-body
    @close="closeEvent"
  >
    <div class="el-drawer--content">
      <div class="el-drawer--content__body">
        <el-form :model="form" label-position="top" ref="form">
          <!-- Field: Issue Description -->
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Name of category"
                prop="name"
                :rules="validateField()"
              >
                <el-input
                  type="text"
                  v-model="form.name"
                  placeholder="What is the issue?"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- Field: Department -->
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Department attached to this category"
                prop="department"
                :rules="validateField()"
              >
                <el-select
                  v-model="form.department"
                  value-key="id"
                  placeholder="Select the related department"
                >
                  <el-option
                    v-for="department in departments"
                    :key="department.id"
                    :label="department.name"
                    :value="department"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- Service -->
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Select the related service (if any)"
                prop="service"
              >
                <el-select
                  v-model="form.service"
                  placeholder="Select the related service"
                >
                  <el-option
                    v-for="(service, index) in services"
                    :key="index"
                    :label="service.service_name"
                    :value="service.slug"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Resolution time for this category"
                :rules="validateField()"
              >
                <el-button
                  :type="resolutionUnit === 'minutes' ? 'plain' : 'default'"
                  @click="resolutionUnit = 'minutes'"
                >
                  Minutes
                </el-button>
                <el-button
                  :type="resolutionUnit === 'hours' ? 'plain' : 'default'"
                  @click="resolutionUnit = 'hours'"
                >
                  Hour (s)
                </el-button>
                <el-button
                  :type="resolutionUnit === 'days' ? 'plain' : 'default'"
                  @click="resolutionUnit = 'days'"
                >
                  Day (s)
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                :label="`How many ${form.resolution_unit}?`"
                prop="resolution_time"
                :rules="validateField()"
              >
                <el-input
                  type="number"
                  v-model="form.resolution_time"
                  placeholder="What is the issue?"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="el-drawer--footer is-align-center">
      <el-button
        v-if="action === 'add'"
        type="primary"
        :loading="adding"
        @click="add"
        >Add Category</el-button
      >
      <el-button v-else type="primary" :loading="updating" @click="update"
        >Save Changes</el-button
      >
    </div>
  </el-drawer>
</template>
  
  <script>
import { setDepartments } from "@/components/Feedback/feedback.config";
import categories from "@/requests/feedback/categories";
export default {
  name: "IssueCategoriesForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    show: {
      type: Boolean,
      default: false,
    },
    issue: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      adding: false,
      updating: false,
      form: {
        department: null,
        service: "",
        resolution_unit: "minutes",
        resolution_time: "",
        name: "",
      },
      departments: setDepartments(),
      categories: [],
    };
  },
  mounted() {
    if (this.action === "edit") {
      this.form = { ...this.issue };
    }
    if (this.location === "NG") {
      this.departments = this.departments.filter(
        (dept) => dept.name !== "Beauty",
      );
    }
  },
  computed: {
    resolutionUnit: {
      get() {
        return this.form.resolution_unit;
      },
      set(value) {
        this.form.resolution_unit = value;
      },
    },
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    services() {
      const val = this.$store.getters.services;

      if (
        this.form.department?.name === "Product" ||
        this.form.department?.name === "Engineering"
      ) {
        return [
          ...val,
          {
            service_name: "Not Applicable",
            slug: "",
          },
        ];
      }

      return val;
    },
    showAffectedUserType() {
      const departments = ["Product and Engineering"];
      return (
        this.form.department &&
        departments.includes(this.form.department.name) &&
        this.form.order_type === "subscription"
      );
    },
    location() {
      return this.$store.getters.location;
    },
  },
  watch: {
    show() {
      if (this.show) {
        if (this.action === "edit") {
          this.form = this.issue;
          this.resolutionUnit = this.issue.resolution_unit;
        }
      }
    },
  },
  methods: {
    closeEvent() {
      this.setShow = false;
      this.form = {
        department: null,
        service: "",
        resolution_unit: "minutes",
        resolution_time: "",
        name: "",
      };
    },
    setOrderInformation() {
      const service = this.setServiceType(this.order.service);
      const departments = {
        beauty: "Beauty",
        cleaning: "Cleaning",
        laundry: "Laundry",
        meal: "Food",
      };

      this.form.department = this.departments.find((department) =>
        department.name.includes(departments[service]),
      );
      this.form.service = service;
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        this.form.department = this.form.department.name;
        const payload = {
          ...this.form,
        };
        categories
          .add(payload)
          .then((response) => {
            const { status, data } = response.data;

            if (status && data.length) {
              this.adding = false;
            }
            this.$message.success(response.data.message);
            this.$emit("success");
            this.closeEvent();
            this.adding = false;
          })
          .catch((error) => {
            this.adding = false;
            this.$message.error(error.response.data.message);
          });
      });
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.updating = true;
        this.form.department = this.form.department.name;
        const payload = {
          ...this.form,
          category_id: this.issue.id,
        };
        categories
          .update(payload)
          .then((response) => {
            const { status, data } = response.data;
            if (status && data.length) {
              this.updating = false;
            }
            this.$message.success(response.data.message);
            this.$emit("success");
            this.closeEvent();
            this.updating = false;
          })
          .catch((error) => {
            this.updating = false;
            this.$message.error(error.response.data.message);
          });
      });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.specific-section {
  &--header {
    padding: 8px 12px;
    background: var(--eden-grey-septenary);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0 15px;

    p {
      font-weight: 500;
      font-size: 0.875rem;
    }

    i {
      font-weight: 600;
      font-size: 0.875rem;
    }
  }

  &--content {
    opacity: 1;
    transition: opacity 0.25s ease-in;
  }

  &.close {
    .specific-section--content {
      opacity: 0 !important;
      transition: opacity 0.25s ease-in;
    }
  }
}

.el-drawer--footer {
  .el-button {
    margin-bottom: 20px;
  }
}
</style>
  